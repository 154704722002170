import React from "react"
import { Helmet } from "react-helmet"
import "normalize.css"

export const SEO = ({ description = '', lang = 'en-EN', title = 'Future Bank' }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      description={description || ''}
    />
  )
}
